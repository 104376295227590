<template>
    <div class="font-montserrat">
      <div class="w-screen section-container laybareplus-banner relative space-top pl-32 mobileL:pl-0">
        <div class="laybareplus-intro px-4 z-10 max-w-md xl:max-w-xl 2xl:max-w-3xl h-full flex flex-col gap-4 xl:gap-5 2xl:gap-8 items-end mobileL:items-start justify-center ml-auto mr-0 mobileL:mr-1 xl:mr-14 2xl:mr-20">
              <img class="w-6 mobileL:w-16 xl:w-24 2xl:w-28" src="../../assets/images/home-page/flower.png" alt="Flower">
              <div class="leading-none">
                  <h1 class="font-mermaid text-right mobileL:text-left text-white-1 text-2xl mobileL:text-5xl 2xl:text-7xl">
                    Walk in and<br>strut out.
                  </h1>
              </div>
              <p class="text-base mobileL:text-lg xl:text-2xl 2xl:text-3xl text-right mobileL:text-left text-white-1">Lay Bare Plus is your destination for
              all things waxing, skin care and brows! With our elite skin
              treatments, you'll leave feeling refreshed and confident!</p>
        </div>
      </div>
  
      <div class="w-screen hello-gorgeous-section md:p-20">
          <div class="content-wrapper max-w-xl xl:max-w-4xl 2xl:max-w-5xl mx-auto text-center leading-tight py-5 mobileL:py-0 px-5">
              <h2 class="font-mermaid font-bold mobileL:font-normal text-white-1 text-3xl mobileL:text-6xl xl:text-8xl 2xl:text-10xl">Hello Gorgeous!</h2>
              <p class="text-base mobileL:text-xl xl:text-3xl 2xl:text-4xl text-white-1 font-semibold">Our passion for
              waxing has gone beyond waxing itself. Lay Bare Plus believes in the
              whole customer experience - from start to finish.</p>
          </div>
      </div>

      <div class="the-plus-factor-section md:p-20 border" id="why-laybare-plus">
          <div class="flex flex-col py-3 mobileL:py-0 mobileL:flex-row items-center text-green-10 max-w-5xl px-16 mobileL:px-24 mobileL:mx-auto gap-4 mobileL:gap-20 2xl:gap-36">
              <div class="font-mermaid gap-2 mobileL:gap-0 flex flex-row mobileL:flex-col text-center mobileL:text-right leading-none">
                  <h2 class="text-4xl mobileL:text-8xl xl:text-9xl 2xl:text-10xl">The <br>Plus <br>Factor</h2>
              </div>
  
              <div class="flex-1 font-montserrat text-center mobileL:text-left font-semibold text-base mobileL:text-lg xl:text-2xl 2xl:text-2xl leading-tight">
                  <p class="mb-8">Our indulgent body care range delivers ahead-of-the-trend
                  skin treatments. From our founding waxing product, the cold
                  jelly wax. Our range has evolved with pampering services, each
                  one freshly made using only premium & natural ingredients.</p>
  
                  <p>We believe in the power of value - getting value for your money,
                  getting value in your experience, and above all. Valuing
                  oneself.</p>
              </div>
          </div>
      </div>
  
      <div class="the-plus-factor-2-section md:p-20 relative bg-brown-6 text-center text-pink-11">
          <img class="absolute top-0 left-0 w-12 mobileL:w-20 xl:w-28 2xl:w-32" src="../../assets/images/home-page/cucumber.png" alt="Cucumber">
          <img class="absolute bottom-0 left-0 w-20 mobileL:w-40 xl:w-44" src="../../assets/images/home-page/pandan.png" alt="Pandan">
          <img class="absolute -top-8 right-4 mobileL:-top-5 mobileL:right-16 w-24 mobileL:w-28 2xl:w-44" src="../../assets/images/home-page/watermelon.png" alt="Watermelon">
          <img class="absolute -top-16 mobileL:-top-24 2xl:-top-32 right-2 mobileL:right-1 w-12 mobileL:w-20 2xl:w-32" src="../../assets/images/home-page/strawberry.png" alt="Strawberry">
          <img class="absolute -bottom-10 mobileL:-bottom-16 2xl:-bottom-24 right-2 mobileL:right-1 w-24 mobileL:w-32 xl:w-44 2xl:w-60" src="../../assets/images/home-page/cucumber-cocoa.png" alt="Cucumber Cocoa">
  
          <div class="plus-factor-2 max-w-5xl mobileL:max-w-6xl 2xl:max-w-full mx-auto">
              <h3 class="text-4xl mobileL:text-5xl 2xl:text-7xl font-mermaid mb-4 mobileL:mb-0">The Plus Factor</h3>
              <div class="grid grid-cols-1 mobileL:grid-cols-3 justify-center items-center mb-7 2xl:mb-10">
                <div class="plus-factor-container mb-3 mobileL:mb-0">
                    <div class="px-16 mobileL:px-7 xl:px-3 2xl:px-14">
                        <h3 class="text-xl mobileL:text-2xl xl:text-3xl 2xl:text-4xl font-bold">PAMPERED FROM HEAD-TO-TOE</h3>
                    </div>
                    <div class="px-16 mobileL:px-6 2xl:px-14" v-show="shouldNotShowElement">
                      <p class="text-base mobileL:text-lg xl:text-xl 2xl:text-2xl">Our approach does not end in hair-removal alone. You can bid those skin worries away with our new treatments.</p>
                    </div>
                </div>
                <div class="plus-factor-container mb-3 mobileL:mb-0">
                    <div class="px-16 mobileL:px-7 xl:px-3 2xl:px-14">
                        <h3 class="text-xl mobileL:text-2xl xl:text-3xl 2xl:text-4xl font-bold">FUSS-FREE</h3>
                    </div>
                    <div class="px-16 mobileL:px-6 2xl:px-14" v-show="shouldNotShowElement">
                      <p class="text-base mobileL:text-lg xl:text-xl 2xl:text-2xl">Our formula has evolved to providing other benefits for your skin concerns and promises a more comfortable waxing experience.</p>
                  </div>
                </div>
                <div class="plus-factor-container mb-3 mobileL:mb-0">
                    <div class="px-16 mobileL:px-7 xl:px-3 2xl:px-14">
                        <h3 class="text-xl mobileL:text-2xl xl:text-3xl 2xl:text-4xl font-bold">RELAX WHILE YOU RELAX</h3>
                    </div>
                    <div class="px-16 mobileL:px-6 2xl:px-14" v-show="shouldNotShowElement">
                      <p class="text-base mobileL:text-lg xl:text-xl 2xl:text-2xl">Add an extra layer of relaxation to your treatment in a haven where you can disconnect from the outside world and reconnect with yourself.</p>
                    </div>
                </div>
              </div>
              <div class="grid grid-cols-1 mobileL:grid-cols-3 justify-center" v-show="shouldShowElement">
                  <div class="px-6 2xl:px-14">
                      <p class="text-lg xl:text-xl 2xl:text-2xl">Our approach does not end in hair-removal alone. You can bid those skin worries away with our new treatments.</p>
                  </div>
                  <div class="px-6 2xl:px-14">
                      <p class="text-lg xl:text-xl 2xl:text-2xl">Our formula has evolved to providing other benefits for your skin concerns and promises a more comfortable waxing experience.</p>
                  </div>
                  <div class="px-6 2xl:px-14">
                      <p class="text-lg xl:text-xl 2xl:text-2xl">Add an extra layer of relaxation to your treatment in a haven where you can disconnect from the outside world and reconnect with yourself.</p>
                  </div>
              </div>
          </div>
      </div>
  
      <div class="testimonials-section w-screen p-5 mobileL:p-10 py-10 mobileL:py-16 bg-pink-12 text-center text-white-1" id="testimonials">
        <img class="w-full mobileL:w-6/12 mx-auto mb-10 mobileL:mb-20" src="../../assets/images/home-page/testimonials-title.png" alt="Testmonials">
        <div class="testimonial-container px-16">
            <div class="grid mobileL:grid-cols-3 justify-center items-center mb-5 mobileL:mb-20">
                <div class="grid-item pb-4 flex flex-col justify-center gap-2 mobileL:gap-14 px-0 mobileL:px-8 h-full" >
                  <img class="w-5 mobileL:w-8 xl:w-12 2xl:w-16 mx-auto pt-5" src="../../assets//images/home-page/quote.png" alt="Quotation mark">
                  <p class="text-base mobileL:text-xl 2xl:text-3xl mobileL:leading-relaxed">I highly suggest their Body Exfoliation services! It made my skin super soft and fresh. I love the strawberry scent as well.</p>
                  <h4 class="text-base mobileL:text-xl 2xl:text-3xl font-bold">W.</h4>
              </div>
  
              <div class="grid-item pb-4 flex flex-col justify-center gap-2 mobileL:gap-14 px-0 mobileL:px-8 h-full"  v-show="isVisible || shouldShowElement">
                  <img class="w-5 mobileL:w-8 xl:w-12 2xl:w-16 mx-auto pt-5" src="../../assets//images/home-page/quote.png" alt="Quotation mark">
                  <p class="text-base mobileL:text-xl 2xl:text-3xl mobileL:leading-relaxed">I have sensitive skin and
                  I prefer getting waxed at Lay Bare Plus because of their
                  flavored wax. I usually go for their Cucumber-Pandan wax, it's
                  the best!</p>
                  <h4 class="text-base mobileL:text-xl 2xl:text-3xl font-bold">A.</h4>
              </div>
  
              <div class="flex flex-col justify-center gap-2 mobileL:gap-14 px-0 mobileL:px-8 h-full" v-show="isVisible || shouldShowElement">
                  <img class="w-5 mobileL:w-8 xl:w-12 2xl:w-16 mx-auto pt-5" src="../../assets//images/home-page/quote.png" alt="Quotation mark">
                  <p class="text-base mobileL:text-xl 2xl:text-3xl mobileL:leading-relaxed">Their skin lightening
                  services helped with my hyperpigmentation sa bikini area and
                  underarms. Slowly but surely, you'll see the results</p>
                  <h4 class="text-base mobileL:text-xl 2xl:text-3xl font-bold">D.</h4>
              </div>
          </div>
          <div class="grid mobileL:grid-cols-3 justify-center items-center" v-show="isVisible">
              <div class="grid-item-with-top py-4 mobileL:py-0 mobileL:border-none flex flex-col justify-center gap-2 mobileL:gap-14 px-0 mobileL:px-8 h-full">
                  <img class="w-5 mobileL:w-8 xl:w-12 2xl:w-16 mx-auto pt-5" src="../../assets//images/home-page/quote.png" alt="Quotation mark">
                  <p class="text-base mobileL:text-xl 2xl:text-3xl mobileL:leading-relaxed">I love visiting Lay Bare
                  Plus beacause their branches are so posh and cozy. It's leaves
                  you feeling relaxed after every visits</p>
                  <h4 class="text-base mobileL:text-xl 2xl:text-3xl font-bold">F.</h4>
              </div>
          </div>
          <button class="toggle-btn bg-green-10 mt-5 text-white-1 font-extrabold px-4 xl:px-5 2xl:px-7 py-2 xl:py-3 2xl:py-4 rounded-full text-base xl:text-xl 2xl:text-2xl" @click="showMoreFeedback()" ref="toggleButton">
              Load More!
          </button>
        </div>
      </div>
    </div>
</template>
<script>
export default {
    metaInfo: {
        title: "Lay Bare Plus",
        titleTemplate: "%s",
        htmlAttrs: {
          lang: "en-US",
        },
        meta: [
          { charset: "utf-8" },
          {
            vmid: 'description',
            name: "description",
            content:
              "Lay Bare Plus is your destination for all things waxing, skin care, and brows! With our elite skin treatments, you’ll leave feeling refreshed!",
          },
          { vmid: 'thumbnail', name: "thumbnail", content: "https://lay-bare.com/Lay-Bare-Plus.png" },
          { property: "og:title", content: "Lay Bare Plus" },
          { property: "og:site_name", content: "Lay Bare Plus" },
          { property: "og:url", content: "https://lay-bare.com/laybareplus" },
          { property: "og:type", content: "website" },
          {
            vmid: 'og:description',
            property: "og:description",
            content:
              "Lay Bare Plus is your destination for all things waxing, skin care, and brows! With our elite skin treatments, you’ll leave feeling refreshed!",
          },
          { property: "og:image", content: "https://lay-bare.com/Lay-Bare-Plus.png" },

          { vmid: 'twitter:card', name: "twitter:card", content: "summary" },
          { vmid: 'twitter:site', name: "twitter:site", content: "@Lay_Bare" },

          { name: "viewport", content: "width=device-width, initial-scale=1" },
          { name: "robots", content: "index,follow" },
        ]
    },
    data() {
        return {
            isVisible: false,
            windowWidth: window.innerWidth,
        }
    },

    computed: {
    shouldShowElement() {
        return this.windowWidth > 480; 
    },

    shouldNotShowElement(){
        return this.windowWidth < 480; 
    },
    
    
    },
    mounted() {
    window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    },
    methods: {
    handleResize() {
        this.windowWidth = window.innerWidth;
    },
    showMoreFeedback() {
        this.isVisible = !this.isVisible;
        this.$refs.toggleButton.innerText = this.isVisible ? 'Load Less!' : 'Load More!';
    }
    }
}
</script>

<style scoped>
.section-container {
    height: 600px;
}

.space-top {
    margin-top: 90px;
}


.laybareplus-banner {
    background: url('../../assets/images/home-page/lay-bare-plus-banner.jpg') no-repeat;
    background-size: cover;
}

.hello-gorgeous-section {
    background: url('../../assets/images/home-page/lb-banner2.jpg') no-repeat;
    background-size: cover;
}

.hello-gorgeous-section h2 {
    text-shadow: 0px 8px #00000040;
}

.the-plus-factor-section {
    background: url('../../assets/images/home-page/lb-banner3.jpg') no-repeat;
    background-size: cover;
}

.grid-item:not(:nth-child(3n)) {
    border-right: 3px solid #fff;
}

.toggle-btn {
    box-shadow: 0px 4px 0px 0px #3D7468;
}

@media (max-width: 480px){

    .section-container{
    height: 289px;
    }
    .space-top {
    margin-top: 60px;
    }

    .the-plus-factor-section br {
    display:none;
    }

    .plus-factor-2 {
    height:600px;
    }
    
    .grid-item:not(:nth-child(3n)) {
    border-bottom: 3px solid #fff;
    border-right: 0px;
    }
    .grid-item-with-top:not(:nth-child(3n)) {
    border-bottom: 3px solid #fff;
    border-top: 3px solid #fff;
    border-right: 0px;
    }

}

@media (min-width: 1280px) {
    .space-top {
    margin-top: 100px;
    }

    .section-container {
    height: calc(100vh - 100px);
    }

    .when-you-see-hair-content {
    font-size: 5.25rem;
    }
}

@media (min-width: 1536px) {
    .space-top {
    margin-top: 128px;
    }

    .section-container {
    height: 110vh;
    }

    .when-you-see-hair-content {
    font-size: 6.25rem;
    }
}
</style>